<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <side-bar @upText='setTopText' style='min-height: 420px;'>
      <template slot-scope="" slot="links">
        <!-- <sidebar-item :link="{name: '會員資訊', icon: 'nc-icon nc-single-02', path: '/member'}" v-if="checkScope(['member:r'])"></sidebar-item> -->
        <!-- <sidebar-item :link="{name: '活動資訊', icon: 'nc-icon nc-paper-2', path: '/activity'}" v-if="checkScope(['activity:r'])" ></sidebar-item>
        <sidebar-item :link="{name: '一般資訊', icon: 'nc-icon nc-notes', path: '/general' }" v-if="checkScope(['support:r','policy:r'])"></sidebar-item> -->
        <!-- <sidebar-item :link="{name: '管理者設定', icon: 'nc-icon nc-badge', path: '/organization'}" v-if="checkScope(['user:r','organization:r'])"></sidebar-item> -->
        <!-- <sidebar-item :link="{name: 'Setting', path: '/settings'}" class='navBottom'></sidebar-item> -->

        <!-- <sidebar-item :link="{name: 'Management', path: '/member'}" v-if="checkScope(['member:r'])"></sidebar-item>
        <sidebar-item :link="{name: 'Dataset', path: '/member'}" v-if="checkScope(['member:r'])"></sidebar-item>
        <sidebar-item :link="{name: 'Decision Making', path: '/member'}" v-if="checkScope(['member:r'])"></sidebar-item>
        <sidebar-item :link="{name: 'Case Decision', path: '/member'}" v-if="checkScope(['member:r'])"></sidebar-item>
        <sidebar-item :link="{name: 'Data Analytics', path: '/member'}" v-if="checkScope(['member:r'])"></sidebar-item>
        <sidebar-item :link="{name: 'Search', path: '/member'}" v-if="checkScope(['member:r'])"></sidebar-item>
         -->
        <sidebar-item :link="{ name: 'Management', icon: 'nc-icon nc-grid-45' }" v-if='userIsSuper()'>
          <sidebar-item :link="{ name: 'Member', path: '/member' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Hospital', path: '/hospital' }"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'Management', icon: 'nc-icon nc-grid-45' }" v-else-if='userIsAdmin()'>
          <sidebar-item :link="{ name: 'Group', path: '/group' }"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Dataset', icon: 'nc-icon nc-notes' }">
          <sidebar-item :link="{name: 'Overview', path: '/dataset-overview' }"></sidebar-item>
          <sidebar-item :link="{name: 'Download', path: '/dataset-download' }"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Case Parameter', icon: 'nc-icon nc-notes', path: '/case-parameter' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Setting', icon: 'nc-icon nc-settings-tool-66' }">
          <sidebar-item :link="{ name: 'Personal Data', path: '/personaldata' }" v-if='!userIsSuper()'></sidebar-item>
          <sidebar-item :link="{ name: 'Reset Password', path: '/resetpassword' }"></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar :title='title' :subText='subText'></top-navbar>

      <dashboard-content @click.native="toggleSidebar" @upText='setTopSubText'>

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import lib from '../../../lib'
  // import MobileMenu from './Extra/MobileMenu.vue'
  // import UserMenu from './Extra/UserMenu.vue'
  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent
    },
    data () {
      return {
        title: '',
        subText: ''
      }
    },
    methods: {
      userIsSuper() {
        return lib.userIsSuper()
      },
      userIsAdmin() {
        return lib.userIsAdmin()
      },
      checkScope: lib.checkScope,
      setTopText(text) {
        this.title = text
        this.subText = ''
      },
      setTopSubText(text) {
        this.subText = text
      },
      getUserRole() {
        var user = lib.getUser();
        return user.role
      },
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    }
  }

</script>
<style scoped>
  .navBottom{
    bottom: 0;
    position: absolute;
    display: block;
    width: 100%;
  }

</style>>